@import 'config';
@import 'neat/neat';
// @import url(http://fonts.googleapis.com/earlyaccess/notosanssc.css);

body {
  position: relative;
  font-family: "Hiragino Sans GB", "Microsoft YaHei",
             "WenQuanYi Micro Hei", sans-serif;
}

#wrapper {
  margin: 0;
  padding: 0;
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
}

.inner {
  text-align: center;
  position: relative;
  min-width: 840px;
  max-width: 960px;
  padding: 0 25px;
  margin: 0 auto;
  h2 {
    &::after {
      content: attr(data-desc);
    }
  }
}

.button {
  border-radius: 4px;
  display: block;
  outline: 0;
  cursor: pointer;
}

.shadow {
  @include position(absolute, 0 0 0 0);
  @include transition(opacity .3s ease);
  background-color: rgba(0,0,0,0.36);
  opacity: 0;
  &-download {
    @include position(fixed, 50% 0 null 0 null);
    @include size(390px 208px);
    margin: 0 auto;
    background-color: #fff;
    @include transform(translateY(-50%) scale(.8));
    transition: transform .2s ease-out;
    font-size: 16px;
    color: #a1abab;
    &__left,
    &__right {
      height: 100%;
      display: inline-block;
      padding: 26px 36px;
      float: left;
    }
    &__left {
      position: relative;
      &::after {
        content: " ";
        @include size(1px 52%);
        @include position(absolute, null 3px 41px null);
        background-color: #c7caca;
      }
    }
  }
  h5 {
    margin: 0 0 18px 0;
    font-size: 16px;
    font-weight: normal;
  }
  &-qrcode {
    @include size(112px);
    border: 2px #67dadf solid;
    background-image: url("../images/qrcode.png");
    background-repeat: no-repeat;
    background-size: 108px 108px;
    border-radius: 4px;
    background-position: center center;
  }
  .button {
    &-download {
      @include size(132px 48px);
      line-height: 48px;
      text-align: center;
      padding-left: 15px;
      // padding: 13px 17px;
      color: #fff;
      font-size: 18px;
      background-color: #00cbd0;
      background-repeat: no-repeat;
      background-position: 14px 11px;
      background-size: 22px;
      position: relative;
      transition: transform .2s, backgroundColor .3s;
      @include transform-style(preserve-3d);
      &__ios {
        margin-bottom: 16px;
        background-image: url("../images/icon_ios_nor.png");
        background-size: 22px 22px;
      }
      &__android {
        background-image: url("../images/icon_android_nor.png");
        background-size: 22px 22px;
      }
      // &:hover,
      &:active,
      &:hover {
        @include transform(translateY(2px));
        background-color: darken(#00cbd0, 3%);
      }
    }
    &-close {
      @include size(48px);
      @include position(fixed, 80px 8% null null);
      @include retina-image("../images/btn_back", contain);
      background-repeat: no-repeat;
      &:hover {
        background-color: rgba(0,0,0,0.3);
      }
    }
  }
  &.appear {
    opacity: 1;
    z-index: 9999;
    .shadow-download {
      @include transform(translateY(-50%) scale(1));
    }
  }
  &.disappear {
    opacity: 0;
  }
}

.overview {
  // @include linear-gradient(to right, #00cbd1, #05d2b7);
  @include background(url("../images/home_bg.png"), linear-gradient(to right, #00cbd1, #05d2b7), center no-repeat);
  // @include retina-image("../images/home_bg", 0 0);
  // background-image: url("../images/home_bg.png");
  background-position: center top;
  background-repeat: no-repeat;
}

.about-header {
  background-image: url("../images/about_head_bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-attachment: fixed;
  .inner {
    min-height: 557px;
  }
}

#navbar {
  font-size: 16px;
  height: 56px;
  background-color: rgba(255,255,255,.2);
  font-weight: bold;
}

.navbar {
  &_menu {
    @include position(absolute, null 25px null null);
    padding: 0;
    margin: 0;
  }
  &_item {
    float: left;
    display: inline-block;
    margin-right: 28px;
    line-height: 56px;
    color: rgba(0,0,0,0.2);
    a {
      &::before {
        color: rgba(255,255,255,.9);
        // content: attr(data-hover);
        position: absolute;
        transition: transform .3s, opacity .3s;
      }
      &:hover {
        color: rgba(255,255,255,.8);
        &::before {
          @include transform(scale(.9));
          opacity: 0;
        }
      }
    }
    &--active {
      color: #fff;
      a {
        &::before {
          color: #fff;
        }
      }
    }
  }
  &_logo {
    float: left;
    a {
      display: block;
      margin-top: 14px;
      @include size(164px 27px);
      background-image: url("../images/about_logo.png");
      background-size: contain;
      background-repeat: no-repeat;
    }

    &--shadow {
      a {
        @include retina-image("../images/about_logo_shadow", contain);
      }
    }
  }
}

.parallax {
  background-position: 50% 0;
  background-attachment: fixed;
  .section {
    .inner {
      min-height: 557px;
      padding: 0 48px;
      color: #fff;
      @include display(flex);
      @include flex-direction(column);
      @include justify-content(center);
    }
    h1, h2, p {
      width: 40%;
      font-weight: bold;
    }
  }
  .subsection {
    h2 {
      font-size: 52px;
      margin-bottom: 40px;
      position: relative;
      font-weight: bold;
      &::after {
        font-size: 18px;
        @include position(absolute, -58px null null null);
        padding-bottom: 8px;
        border-bottom: 2px solid #fff;
      }
    }
    p {
      font-size: 22px;
      line-height: 1.3;
      white-space: pre-line;
    }
  }
}

.section-banner {
  h1 {
    margin: 0;
    padding-bottom: 15px;
    font-size: 62px;
    max-width: 750px;
    line-height: 1.2em;
    font-family: 'Verdana', sans-serif;
  }
  h2 {
    margin: 0;
    font-size: 38px;
    padding-bottom: 0;
    max-width: 500px;
    letter-spacing: 4px;
  }
  p {
    margin: 24px 0;
    font-size: 26px;
  }
  .slogan {
    @include size(282px 30px);
    @include retina-image("../images/home_title_utips", 100% 100%);
    background-repeat: no-repeat;
    margin: 24px 0;
  }
  .seperator {
    margin-top: 18px;
  }
  .button-download__main {
    @include size(188px 56px);
    font-family: "Hiragino Sans GB", "Microsoft YaHei", "WenQuanYi Micro Hei", sans-serif;
    background-color: transparent;
    font-weight: bold;
    border: 2px solid #fff;
    color: #fff;
    padding-left: 32px;
    font-size: 23px;
    text-align: center;
    @include retina-image("../images/icon_download_home", 23px 23px);
    background-repeat: no-repeat;
    background-position: 20px 15px;
    position: relative;
    // @include transition(color .2s ease, backgroundColor .2s ease);
    &::after {
      @include size(100% 0);
      content: "";
      @include position(absolute, 0 null null 0);
      // z-index: -1;
      transition: height .3s, content .3s;
      background-color: #fff;
    }
    &:hover,
    &:active {
      @include retina-image("../images/icon_download_sel", 23px 23px);
      // background-color: #fff;
      color: #09cbc3;
      &::after {
        height: 100%;
        content: "立即下载";
        line-height: 56px;
        @include retina-image("../images/icon_download_sel", 23px 23px);
        background-repeat: no-repeat;
        background-position: 20px 15px;
        padding-left: 32px;
      }
    }
  }
  .preview {
    &-shell,
    &-div {

    }
    &-shell {
      @include size(270px 440px);
      @include position(absolute, null 92px 0 null);
      background-image: url("../images/home_iphone.png");
      background-size: contain;
    }
    &-div {
      @include size(236px auto);
      @include position(absolute, null 109px 0 null);
      overflow: hidden;
      background-color: #22cbd0;
    }
  }
}

#preview-slider {
  text-align: left;
  margin: 0;
  font-size: 0;
  position: relative;
  width: 500%;
  will-change: transform;
  @include transform(translateX(0px) translate3d(0,0,0));
  @include animation(10s slider infinite);
  @include animation-delay(3s);
  @include animation-timing-function(cubic-bezier(0.275, 1.130, 0.100, .99));
  img {
    float: left;
    width: 20%;
  }
}

@include keyframes(slider) {
  0% {
    @include transform(translateX(0px));
  }
  12.5% {
    @include transform(translateX(0px));
  }
  25% {
    @include transform(translateX(-236px));
  }
  37.5% {
    @include transform(translateX(-236px));
  }
  50% {
    @include transform(translateX(-472px));
  }
  62.5% {
    @include transform(translateX(-472px));
  }
  75% {
    @include transform(translateX(-708px));
  }
  87.5% {
    @include transform(translateX(-708px));
  }
  100% {
    @include transform(translateX(-944px));
  }
}

.topics {
  background-color: #6b7ef4;
  background-image: url("../images/home_headlines_bg.png");
  background-size: 496px 222px;
  h2 {
    &::after {
      right: 0;
    }
  }
  .headlines-div {
    @include position(absolute, 50% 36px null null);
    @include transform(translateY(-50%));
    div {
      background-size: cover;
      margin-bottom: 14px;
    }
    &__1 {
      @include size(323px 172px);
      @include retina-image("../images/home_headlines_1", 0 0);
      // top: 0;
    }
    &__2 {
      @include size(306px 72px);
      @include retina-image("../images/home_headlines_2", 0 0);
      margin-left: 16px;
    }
    &__3 {
      @include size(306px 53px);
      @include retina-image("../images/home_headlines_3", 0 0);
      margin-left: 16px;
    }
  }
}

.categories {
  background-color: #4da7ff;
  background-image: url("../images/home_subscribe_bg.png");
  background-size: 496px 222px;
  h2 {
    &::after {
      left: 0;
    }
  }
  &-div {
    @include position(absolute, 50% null null 48px);
    @include transform(translateY(-50%));
    div {
      background-size: cover;
    }
    &__1 {
      @include size(294px 320px);
      @include retina-image("../images/home_subscribe_phone", 0 0);
    }
    &__2 {
      @include size(78px 56px);
      @include retina-image("../images/home_subscribe_square_left", 0 0);
      @include position(absolute, 56px null null -10px);
    }
    &__3 {
      @include size(87px 64px);
      @include retina-image("../images/home_subscribe_square_right", 0 0);
      @include position(absolute, 10px -40px null null);
    }
  }
}

.tools {
  background-color: #ffe189;
  background-image: url("../images/home_tool_bg.png");
  background-size: 496px 222px;
  text-shadow: 0px 1px 13.72px rgba(210, 143, 0, 0.235);
  h2 {
    &::after {
      right: 0;
    }
  }
  &-div {
    @include position(absolute, 50% 48px null null);
    @include transform(translateY(-50%));
    max-width: 344px;
    div {
      background-size: cover;
      @include size(152px);
      float: left;
      margin: 10px;
    }
    &__1 {
      @include retina-image("../images/home_tool_bus", 0 0);
    }
    &__2 {
      @include retina-image("../images/home_tool_score", 0 0);
    }
    &__3 {
      @include retina-image("../images/home_tool_class", 0 0);
    }
    &__4 {
      @include retina-image("../images/home_tool_add", 0 0);
    }
  }
}

.activities {
  background-color: #57dac7;
  background-image: url("../images/home_activity_bg.png");
  background-size: 496px 222px;
  h2 {
    &::after {
      left: 0;
    }
  }
  &-div {
    @include position(absolute, 50% null null 169px);
    @include transform(translateY(-50%));
    min-height: 300px;
    div {
      background-size: cover;
      @include size(180px 276px);
      border-radius: 10px;
      @include position(absolute, 0 null null 0);
      @include transform(scale(.9));
    }
    &__1 {
      background-color: #bcf0e9;
      opacity: .6;
    }
    &__2 {
      background-color: #cdf4ee;
      opacity: .2;
    }
    &__3 {
      @include retina-image("../images/home_activity_ning", 0 0);
      opacity: 0;
    }
  }
  .fanOutOne {
    @include animation(fanOutOne 1s ease forwards);
  }
  .fanOutTwo {
    @include animation(fanOutTwo 1s ease forwards);
  }
  .fanOutThree {
    @include animation(fanOutThree 1s ease forwards);
  }
}

.more {
  background-color: #fff;
  .inner {
    
  }
  &-media {
    width: 23%;
    display: inline-block;
    padding: 64px 26px 0 26px;
    text-align: center;
    position: relative;
    &:hover {
      & > .thumb {
        @include transform(translateY(-3px));
      }
    }
    &::after {
      @include size(1px 75%);
      @include position(absolute, 50% 0 null null);
      @include transform(translateY(-50%));
      background-color: #e7e7e7;
    }
    .thumb {
      @include size(60px);
      display: inline-block;
      margin: 0 auto 24px auto;
      background-size: 100% 100%;
      transition: transform .2s ease;
    }
    h4 {
      font-size: 18px;
      margin: 0;
    }
    p {
      white-space: pre-line;
      font-size: 14px;
      line-height: 1.5;
      color: #6e6e6e;
      margin: 24px 0 64px 0;
    }
    &__original {
      .thumb {
        @include retina-image("../images/home_more_ychw", contain);
      }
      h4 {
        color: #67dadf;
        font-weight: bold;
      }
      &::after {
        content: " ";
      }
    }
    &__campus {
      .thumb {
        @include retina-image("../images/home_more_xyzn", contain);
      }
      h4 {
        color: #ff807a;
      }
      &::after {
        content: " ";
      }
    }
    &__panoramic {
      &::after {
        content: " ";
      }
      h4 {
        color: #46b7ef;
      }
      .thumb {
        @include retina-image("../images/home_more_xyqj", contain);
      }
    }
    &__night {
      h4 {
        color: #a29bff;
      }
      .thumb {
        @include retina-image("../images/home_more_yjms", contain);
      }
    }
  }
}

.qrcode {
  padding: 44px 0;
  min-height: 466px;
  text-align: center;
  background-color: #fff;
  @include retina-image("../images/home_qrcode_bg", 496px 222px);
  &-header {
    font-size: 34px;
    color: #00cbd0;
    margin: 0 auto 78px auto;
    font-weight: bold;
  }
  &-body {
    max-width: 590px;
    width: 60%;
    min-width: 420px;
    margin: 0 auto;
  }
  img {
    @include size(160px);
  }
  &__iphone,
  &__android {
    @include size(200px);
    border: 2px solid #a1abab;
    border-radius: 10px;
    padding: 20px;
    position: relative;
    &::after {
      @include position(absolute, null 0 -52px 0);
      width: 100%;
      font-size: 28px;
    }
  }
  &-image {
    @include size(200px);
    border: 2px solid #58d6db;
    border-radius: 10px;
    padding: 20px;
    position: relative;
    margin: 0 auto;
  }
  &__iphone {
    float: left;
    &::after {
      content: "iPhone";
    }
  }
  &__android {
    float: right;
    &::after {
      content: "Android";
    }
  }
}

.footer {
  padding-top: 64px;
  .inner {
    display: block;
    max-width: 900px;
    min-width: 789px;
    text-align: left;
    padding: 0 12px 64px 12px;
    border-bottom: 1px #a1a1ab solid;
  }
  &-logo {
    @include size(135px 44px);
    @include retina-image("../images/home_tab_logo", contain);
    margin-bottom: 42px;
  }
  &-body {
    color: #a1abab;
    display: inline-block;
    &__left {
      width: 74%;
      font-size: 16px;
      ul {
        padding-left: 42px;
        margin: 0;
      }
      li {
        line-height: 3;
        position: relative;
        &::before {
          content: " ";
          @include size(28px);
          @include position(absolute, 10px null null -42px);
        }
        &:first-child {
          &::before {
            @include retina-image("../images/icon_address", contain);
          }
        }
        &:nth-child(2) {
          &::before {
            @include retina-image("../images/icon_callphone", contain);
          }
        }
        &:last-child {
          &::before {
            @include retina-image("../images/icon_name", contain);
          }
        }
      }
    }
    &__right {
      width: 20%;
      font-size: 20px;
      ul {
        padding: 0;
        margin: 0;
      }
      li {
        line-height: 2.3;
        &.active,
        &:hover {
          color: #67dadf;
        }
      }
      // a {
      //   overflow: hidden;
      //   display: inline-block;
      //   position: relative;
      //   color: #67dadf;
      //   &::before,
      //   &::after {
      //     transition: transform .3s, opacity .3s;
      //     pointer-events: none;
      //   }
      //   &::before {
      //     color: #a1abab;
      //     content: attr(data-hover);
      //     position: absolute;
      //     text-shadow: 0 0 1px rgba(255,255,255,0.3);
      //   }
      //   &::after {
      //     content: "";
      //     @include position(absolute, null null 0 0);
      //     @include size(100% 2px);
      //     background-color: #67dadf;
      //     opacity: 0;
      //     @include transform(translateY(5px));
      //   }
      //   &:hover,
      //   &:focus {
      //     &::before {
      //       opacity: 0;
      //       @include transform(translateY(-2px));
      //     }
      //     &::after {
      //       opacity: 1;
      //       @include transform(translateY(1px));
      //     }
      //   }
      // }
    }
  }
}

.about.parallax,
.join.parallax {
  text-align: center;
  h2, h3, p {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
  }
  h2 {
    font-size: 52px;
    font-weight: bold;
    position: relative;
    margin-bottom: 100px;
    margin-top: 12px;
    &::after {
      @include position(absolute, null 0 -36px 0);
      margin: 0 auto;
      font-size: 20px;
      content: attr(data-desc);
    }
  }
  p {
    text-align: left;
    margin-bottom: 34px;
    line-height: 1.7;
    font-size: 18px;
    font-weight: normal;
  }
  .inner {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

.about {
  &-intro {
    @include retina-image("../images/about_utips_bg", 496px 222px);
    background-color: #00cbd0;
    li {
      margin-bottom: 23px;
      font-size: 18px;
      line-height: 27px;
      text-align: left;
      padding-left: 32px;
      margin-left: 64px;
      border-left: 4px #fff solid;
      position: relative;
      span {
        @include position(absolute, 50% null null -81px);
        @include transform(translateY(-50%));
        font-size: 24px;
        font-weight: bold;
      }
    }
  }
  &-awards {
    @include retina-image("../images/about_awarded_bg", 496px 222px);
    background-color: #41b3ff;
    #awards {
      position: relative;
      margin-left: 64px;
      &::before {
        content: "";
        background-color: #2d98f1;
        @include size(4px 90%);
        @include position(absolute, 11px null 0 -5px);
      }
    }
    .award {
      text-align: left;
      font-size: 18px;
      padding-left: 128px;
      position: relative;
      &::before {
        content: attr(data-desc);
        @include position(absolute, 50% null null 48px);
        @include transform(translateY(-50%));
        font-weight: bold;
        font-size: 22px;
      }
      &::after {
        content: "";
        @include size(48px);
        @include position(absolute, 50% null null -67px);
        background-color: #41b3ff;
        @include transform(translateY(-50%));
        @include retina-image("../images/icon_awarded", 48px 48px);
        background-repeat: no-repeat;
      }
      &:not(:last-child) {
        margin-bottom: 48px;
      }
    }
    .sub-award {
      line-height: 1.8;
      list-style: disc;
      span {
        font-weight: bold;
        margin-right: 26px;
      }
    }
  }
  &-comments {
    @include retina-image("../images/about_media_bg", 496px 222px);
    background-color: #fdac61;
    .comments-text {
      width: 60% !important;
      margin-bottom: 18px !important;
      font-size: 20px !important;
      line-height: 1.3;
      position: relative;
      &::before,
      &::after {
        content: " ";
        @include size(48px);
        position: absolute;
        background-repeat: no-repeat;
      }
      &::before {
        @include retina-image("../images/about_media_symbol_left", contain);
        left: 98px;
        top: 50px;
      }
      &::after {
        @include retina-image("../images/about_media_symbol_right", contain);
        right: 98px;
        top: 50px;
      }
      &__strong {
        font-size: 40px;
        font-weight: bold;
      }
    }
    .about-media {
      width: 690px;
      margin: 58px auto 0 auto;
      div {
        height: 42px;
        background-repeat: no-repeat;
        display: inline-block;
        float: left;
        &:not(:last-child) {
          margin-right: 50px;
        }
      }
      &__1 {
        width: 138px;
        @include retina-image("../images/about_media_dgb", cover);
      }
      &__2 {
        width: 114px;
        @include retina-image("../images/about_media_gzrb", cover);
      }
      &__3 {
        width: 114px;
        @include retina-image("../images/about_media_ycwb", cover);
      }
      &__4 {
        width: 174px;
        @include retina-image("../images/about_media_gztv", cover);
      }
    }
  }
  &-partners {
    background-color: #fff;
    h2 {
      color: #878C99;
    }
    .partners {
      width: 756px;
      margin: 0 auto;
      div {
        display: inline-block;
        float: left;
        margin-right: 70px;
        margin-bottom: 42px;
      }
      &__1 {
        @include size(140px 40px);
        @include retina-image("../images/about_partner_tencent", cover);
      }
      &__2 {
        @include size(128px 40px);
        @include retina-image("../images/about_partner_wyyx", cover);
      }
      &__3 {
        @include size(136px 40px);
        @include retina-image("../images/about_partner_chinamobile", cover);
      }
      &__4 {
        @include size(140px 40px);
        @include retina-image("../images/about_partner_alibaba", cover);
        margin-right: 0 !important;
      }
      &__5 {
        @include size(112px 44px);
        @include retina-image("../images/about_partner_gewara", cover);
        margin-left: 12px;
      }
      &__6 {
        @include size(138px 44px);
        @include retina-image("../images/about_partner_spdbank", cover);
        margin-left: 18px;
      }
      &__7 {
        @include size(138px 44px);
        @include retina-image("../images/about_partner_4399", cover);
        margin-left: -12px;
      }
      &__8 {
        @include size(140px 44px);
        @include retina-image("../images/about_partner_21century", cover);
        margin-right: 0 !important;
      }
    }
  }
}

.join {
  &-header {
    @include retina-image("../images/join_head_bg", cover);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    .inner {
      min-height: 557px;
    }
    .title {
      @include retina-image("../images/join_head_title", 264px 232px);
      @include size(264px 232px);
    }
    .section-banner {
      .inner {
        @include display(flex);
        @include align-items(center);
      }
    }
  }
  &-provide {
    background-color: #41b3ff;
    @include retina-image("../images/join_provide_bg", 496px 222px);
    h2 {
      color: #fff;
    }
    .provisions {
      width: 72%;
      margin: 0 auto;
      &-row {
        @include display(flex);
        @include flex-wrap(wrap);
      }
      &__1,
      &__2,
      &__3,
      &__4 {
        @include flex(1);
        @include display(flex);
        @include align-items(flex-start);
        color: #fff;
      }
      &__1 {
        // color: #ffb26a;
        margin-right: 84px;
        .thumb {
          @include retina-image("../images/join_provide_cyfw", cover);
        }
      }
      &__2 {
        // color: #41b3ff;
        .thumb {
          @include retina-image("../images/join_provide_zyzd", cover);
        }
      }
      &__3 {
        // color: #65e2c0;
        margin-right: 84px;
        .thumb {
          @include retina-image("../images/join_provide_gzhb", cover);
        }
      }
      &__4 {
        // color: #ffd321;
        .thumb {
          @include retina-image("../images/join_provide_xcfl", cover);
        }
      }
    }
    .thumb {
      @include size(76px 76px);
      margin-right: 32px;
    }
    .body {
      @include flex(1);
      text-align: left;
      padding-top: 10px;
    }
    h3 {
      margin: 16px 0 !important;
      font-size: 18px;
    }
    strong {
      font-size: 14px;
      line-height: 1.3;
      border-bottom-style: solid;
      border-bottom-width: 2px;
      padding-bottom: 4px;
    }
  }
  &-need {
    background-color: #fff;
    @include retina-image("../images/join_need_bg", 496px 222px);
    h2 {
      color: #6e6e6e;
    }
    .needs {
      width: 100%;
      margin: 0 auto;
      @include display(flex);
      @include flex-wrap(wrap);
      &__1,
      &__2,
      &__3,
      &__4 {
        @include flex(1);
      }
      &__1 {
        .thumb {
          @include retina-image("../images/join_need_sun", cover);
        }
        h3 {
          color: #ffa05a;
        }
      }
      &__2 {
        .thumb {
          @include retina-image("../images/join_need_trust", cover);
        }
        h3 {
          color: #41b3ff;
        }
      }
      &__3 {
        .thumb {
          @include retina-image("../images/join_need_master", cover);
        }
        h3 {
          color: #ffd321;
        }
      }
      &__4 {
        .thumb {
          @include retina-image("../images/join_need_team", cover);
        }
        h3 {
          color: #78e4d4;
        }
      }
    }
    .thumb {
      @include size(76px);
      margin: 0 auto;
    }
    h3 {
      font-size: 24px;
      margin: 24px 0;
      font-weight: bold;
    }
    p {
      text-align: left;
      color: #6e6e6e;
      font-size: 16px !important;
      line-height: 1.68 !important;
      margin-bottom: 0 !important;
    }
  }

  &-jobs {
    background-color: #00cbd1;
    @include retina-image("../images/join_position_bg", 496px 222px);
    .inner {
      min-height: 226px !important;
      display: block !important;
      margin: 0 !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
      padding-bottom: 0 !important;
      width: 100%;
      max-width: none;
    }
  }

}

.accordion-tabs-minimal {
  $base-border-color: #dcdcdc !default;
  $base-background-color: #fff !default;
  $base-spacing: 1.5em !default;
  $action-color: #477dca !default;
  $dark-gray: #333 !default;
  $medium-screen: em(640) !default;
  $tab-link-color: $dark-gray;
  $tab-border: 1px solid $base-border-color;
  $tab-active-background: $base-background-color;
  $tab-inactive-color: $base-background-color;
  $tab-inactive-hover-color: #c0c0c0;
  $tab-mode: $medium-screen;

  @include clearfix;
  line-height: 1.5;
  padding: 0;

  .tab-header-and-content {
    list-style: none;
    display: inline;
    margin: 0 48px;
    position: relative;
    font-size: 18px;
  }

  .tab-link {
    border-top: $tab-border;
    color: #eee;
    padding: ($base-spacing / 2) $gutter;
    text-decoration: none;
    position: relative;
      border-top: 0;
      display: inline-block;

    &::before {
      @include position(absolute, null null 0 0);
      @include size(100% 3px);
      background-color: #fff;
      content: "";
      transition: transform .3s;
      @include transform(translate3d(0,150%,0));
    }

    &:focus {
      outline: none;
    }

    &.is-active,
    &:hover, {
      color: #fff;
      font-weight: bold;
      &::before {
        @include transform(translate3d(0,0,0));
      }
    }
  }

  .tab-content {
    display: none;
    padding: 1.5em 0 64px 0;
    width: 100%;
    background-color: #fff;
    color: #4d4d4d;
      // border-top: $tab-border;
      float: left;
    position: relative;
    &::after {
      content: " ";
      @include size(75% 1px);
      @include position(absolute, null 0 2px 0);
      background-color: #cacaca;
      margin: 0 auto;
    }
    &.is-open {
      display: block;
    }
  }
}

.subaccordion-tabs {
  padding: 0;
  @include clearfix;
  .subaccordion-tab {
    list-style: none;
    display: inline;
    margin: 0 42px;
  }
  h3 {
    text-align: left;
    font-weight: bold;
    margin: 52px 0 24px 0 !important;
    padding-left: 16px;
    @media (max-width: 821px) {
      text-align: center;
      margin: 36px auto 24px auto !important;
      padding: 0;
    }
  }
  .subtab {
    &-content {
      display: none;
      width: 100%;
      min-width: 821px;
      // padding: 0 15%;
      float: left;
      margin: 0 auto;
      &.is-open__sub {
        @include display(flex);
        @include flex-wrap(wrap);
      }
      &__left {
        width: 45%;
        padding-left: 19%;
      }
      &__right {
        width: 44%;
        padding-left: 8%;
      }
      &__left,
      &__right {
        @media (max-width: 821px) {
          width: 100%;
          padding-left: 0;
        }
      }
    }
    &-link {
      background-color: #fff;
      border: 1px solid #cacaca;
      transition: backgroundColor .3s, color .3s;
      color: #cacaca;
      border-radius: 4px;
      font-size: 16px;
      display: inline-block;
      text-decoration: none;
      padding: 4px 12px;
      cursor: pointer;
      &:hover,
      &.is-active__sub {
        background-color: #00cbd0;
        color: #fff;
        border-color: #00cbd0;
      }
    }
  }
  ol {
    text-align: left;
    font-size: 15px;
    line-height: 1.6;
    padding-left: 32px;
    @media (max-width: 821px) {
      width: 60%;
      margin: 0 auto;
    }
  }
  li {
    list-style: decimal;
  }
}

.email {
  font-size: 16px;
  color: #a1abab;
  margin: 24px auto 54px auto;
  display: inline-block;
  line-height: 3.6;
  background-color: #fff;
  @include position(absolute, null 0 -109px 0);
  span {
    @include size(22px 16px);
    @include retina-image("../images/icon_email", 22px 16px);
    margin-right: 12px;
    display: inline-block;
    position: relative;
    top: 3px;
  }
}

.info {
  padding: 28px 0;
  text-align: center;
  color: #a1abab;
  font-size: 12px;
}

.direction_left {
  .inner {
    text-align: left;
  }
}

.direction_right {
  .inner {
    text-align: right;
  }
}

.text_left {
  .inner {
    @include align-items(flex-start);
  }
}

.text_right {
  .inner {
    @include align-items(flex-end);
  }
}

@include keyframes(switchingTab) {
  0% {
    opacity: 0;
  }
  16.6%, 33.2% {
    opacity:1
  }
  100%, 49.8% {
    opacity:0
  }
}

@include keyframes(fanOutOne) {
  0% {
    @include transform(rotate(-0) scale(.9));
    opacity: .6;
  }
  100% {
    @include transform(rotate(-20deg) scale(1) translateX(-80px));
    opacity: 1;
  }
}

@include keyframes(fanOutTwo) {
  0% {
    @include transform(rotate(-0) translateY(10px) scale(.9));
    opacity: .2;
  }
  100% {
    @include transform(rotate(-8deg) translateX(-40px) translateY(20px) scale(1));
    opacity: 1;
  }
}

@include keyframes(fanOutThree) {
  0% {
    @include transform(rotate(-0) translateY(20px) scale(.9));
    opacity: 0;
  }
  100% {
    @include transform(rotate(4deg) translateY(30px) scale(1));
    opacity: 1;
  }
}