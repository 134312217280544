@import 'variables';

@mixin vertical-align($position: relative) {
    position: $position;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

@mixin respond-to($breakpoint) {
    @if map-has-key($breakpoints, $breakpoint) {
        $value: map-get($breakpoints, $breakpoint);

        @media screen and (min-width: $value) {
            @content;
        }
    } @else {
        @warn "Unknown `#{$breakpoint}` in $breakpoints";
    }
}