@import 'bourbon/bourbon';
@import 'mixin';
@import 'variables';

html,
body {
    margin: 0;
    padding: 0;
    @include size(100% auto);
    background-color: $bgColor;
}

html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
    -webkit-appearance: none;
}

li {
    list-style: none;
}

a {
    text-decoration: none;
    color: inherit;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

p {
    margin: 0;
    padding: 0;
}

.hidden {
    display: none;
}